<template>
    <div class="rounded-3xl w-full reward-card flex justify-center items-center py-4 px-4 mt-3 relative overflow-hidden" style="border: 1px solid rgba(68, 119, 165, 0.6);">
        <img src="../assets/ref-bg.svg" alt="ref-bg">
        <div class="flex flex-col justify-end">
            <h3 class="text-xl text-white font-bold text-end">Refer Friends</h3>
            <h3 class="text-base text-end">
                <span class="text-primary-gray font-semibold">Invite </span>
                <span class="text-white font-bold">{{ refCount }}/{{ currentClaim.requiredRefCount }}</span> 
                <span class="text-primary-gray font-semibold"> friends to claim </span>
                <span class="text-white font-bold">+{{ currentClaim.reward }}</span>
                <span class="text-primary-gray font-semibold"> $NOV</span>

                <button v-on:click="claim" class="rounded-full bg-secondary-green text-white text-base font-bold px-6 py-3 mt-2">
                    {{ isClaimable ? 'Claim' : 'Invite Now' }}
                </button>
            </h3>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RefClaimCard',

    emits: ['onClaim'],
    
    data() {
        return {
            claimLevels: [
                { level: 1, reward: 10, requiredRefCount: 1 },
                { level: 2, reward: 50, requiredRefCount: 3 },
                { level: 3, reward: 150, requiredRefCount: 10 },
                { level: 4, reward: 500, requiredRefCount: 30 },
                { level: 5, reward: 750, requiredRefCount: 50 },
                { level: 6, reward: 3000, requiredRefCount: 100 },
                { level: 7, reward: 20000, requiredRefCount: 500 },
                { level: 8, reward: 50000, requiredRefCount: 1000 },
            ],
        }
    },

    computed: {
        user() {
            return this.$store.state.user;
        },

        icon() {
            return require(`@/assets/box_${4 - this.currentClaim.level % 2}.svg`);
        },

        refCount() {
            return this.$store.state.user.friends.length;
        },

        currentClaim() {
            const currentLevel = this.$store.state.user.refClaimLevel;

            if(!currentLevel) return this.claimLevels[0];

            if(currentLevel > this.claimLevels.length) return this.claimLevels[this.claimLevels.length - 1];

            return this.claimLevels.find((claim) => claim.level == currentLevel);
        },

        isClaimable() {
            if(this.$store.state.user.refClaimLevel > this.claimLevels.length) return false;

            return this.refCount >= this.currentClaim.requiredRefCount;
        },
    },

    methods: {
        claim() {
            if (!this.isClaimable) {
                const link = `t.me/xnovas_bot/app?startapp=${this.user.id}`;

                var input = document.createElement('input');
                input.setAttribute('value', link);
                document.body.appendChild(input);
                input.select();
                document.execCommand('copy');
                document.body.removeChild(input);

                this.$toast.success('Referral link copied to clipboard');
            } else {
                if (this.refCount >= this.currentClaim.requiredRefCount) {
                    this.$emit('onClaim', this.currentClaim.level);
                }
            }
        },
    },
}
</script>