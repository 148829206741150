<template>
  <div class="home flex-col justify-center w-screen h-screen">
    <dialog id="modal-2x" class="modal modal-middle">
      <div class="modal-box h-96 bg-white rounded-2xl p-0 flex flex-col justify-center items-center">
        <button class="absolute top-4 right-4" v-on:click="closeModal">
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.0693 0.000495159C28.2555 0.08988 36.232 8.20359 35.995 18.4788C35.7676 28.3179 27.783 36.0673 17.8513 35.9996C7.69758 35.9318 -0.412937 27.4498 0.0162846 17.2138C0.415718 7.66998 8.3448 -0.0712836 18.0693 0.000495159ZM18.1045 21.8713C18.2507 22.0975 18.3509 22.3074 18.4999 22.4699C19.6115 23.6821 20.6988 24.9172 21.8605 26.0792C22.8517 27.0692 24.3181 27.0773 25.3552 26.2052C26.3586 25.3601 26.5969 23.9164 25.8576 22.7828C25.5529 22.3156 25.1305 21.9242 24.75 21.5097C23.7033 20.3721 22.6513 19.2413 21.5911 18.0955C22.9464 16.6342 24.2558 15.2365 25.5475 13.8226C26.4642 12.8204 26.5319 11.481 25.7533 10.4165C25.0465 9.45226 23.7128 9.07035 22.6215 9.61749C22.134 9.86262 21.7021 10.2621 21.3176 10.6617C20.1992 11.8291 19.1187 13.0317 18.0111 14.2316C16.7722 12.8976 15.5969 11.6192 14.4081 10.3542C13.6864 9.58634 12.8388 9.14619 11.7501 9.46039C9.70964 10.0482 9.06513 12.2543 10.4923 13.8348C11.7745 15.2569 13.0893 16.6491 14.4135 18.0793C14.2632 18.254 14.1386 18.4097 14.0032 18.556C12.8415 19.8155 11.6784 21.0723 10.5166 22.3318C9.44155 23.4992 9.4754 25.0892 10.5938 26.1293C11.6892 27.1478 13.2301 27.0651 14.3268 25.9072C15.5481 24.6179 16.7546 23.3137 18.1045 21.8686V21.8713Z" fill="#323335"/>
          </svg>
        </button>
        <img src="../assets/2x.svg" alt="2x" class="mt-4">
        <h3 class="text-black text-lg font-bold mt-2">Double Claim Bonus</h3>
        <h3 class="text-black text-sm font-semibold mt-1 px-10">Enjoy a double $NOV claim by checking in for free through TON Connect</h3>
        <div class="flex gap-4 mt-7">
          <div class="flex flex-col gap-2 items-center">
            <div class="flex gap-2 items-center">
              <img src="../assets/token_outlined.svg" alt="token" class="w-5 h-5">
              <h1 class="text-primary-gray text-base font-bold">
                <span class="text-black">{{ user.storage.toFixed(2) }}</span>
                <span> $NOV</span>
              </h1>
            </div>
            <button v-on:click="claim(false)" class="bg-blue-button rounded-full px-7 py-2.5"><h1 class="font-bold text-white text-lg">Claim</h1></button>
          </div>
          <div class="flex flex-col gap-2 items-center">
            <div class="flex gap-2 items-center">
              <img src="../assets/token_outlined.svg" alt="token" class="w-5 h-5">
              <h1 class="text-primary-gray text-base font-bold">
                <span class="text-black">{{ user.storage.toFixed(2) * 2 }}</span>
                <span> $NOV</span> 
              </h1>
            </div>
            <button v-on:click="claim(true)" class="bg-secondary-green rounded-full px-7 py-2.5"><h1 class="font-bold text-white text-lg">Claim 2x</h1></button>
          </div>
        </div>
      </div>
    </dialog>
    <div class="content w-screen h-screen flex-col justify-center">
      <div class="flex-col justify-center mt-5 w-screen items-center">
        <div class="flex w-screen justify-center mt-10">
          <div class="rounded-full h-20 w-20 border-white border-4 justify-center flex items-center content-center mt-5 mx-8">
            <div class="absolute bg-white w-16 h-16 rounded-full"></div>
            <h3 class="relative text-black font-bold text-5xl">{{ Array.from(user.name)[0].toUpperCase() }}</h3>
          </div>
        </div>
        <h4 class="text-4xl text-white font-bold mt-2">{{ user.name  }}</h4>

        <div class="flex flex-col justify-center content-end mt-16 w-screen">
          <h4 class="text-white text-2xl font-semibold">Your $NOV Balance</h4>
          <div class="flex justify-center items-center content-center mt-4">
            <svg width="45" height="45" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_27_1325)">
                <path d="M28.1356 56C12.6397 56.0511 0.100255 43.5949 2.17062e-06 28.1465C-0.0982848 13.0146 11.9458 0.174995 27.5223 2.58867e-05C43.3564 -0.176909 55.998 12.3127 56 27.8752C56.002 43.4022 43.5706 55.9489 28.1356 55.9981V56ZM27.9607 51.5963C40.8776 51.6474 51.5004 41.1257 51.5948 28.1897C51.6891 15.1929 41.1547 4.42537 28.2791 4.35853C15.1028 4.28972 4.43864 14.8095 4.39343 27.9224C4.34822 40.9448 14.9003 51.5452 27.9607 51.5963Z" fill="#FDCF00"/>
                <path d="M24.5639 41.4225V31.2075L22.0615 26.094L33.6338 41.4225H40.2308V14.5775H31.5265V22.4452L37.2075 30.2441L22.9146 14.5775H15.7692V41.4225H24.5639Z" fill="#FDCF00"/>
              </g>
              <defs>
                <clipPath id="clip0_27_1325">
                  <rect width="56" height="56" fill="white"/>
                </clipPath>
              </defs>
            </svg>

            <h2 class="text-5xl font-bold text-white ms-3">
              {{ user.balance.toFixed(2) }}
            </h2>
          </div>
        </div> 
        <div class="flex justify-end px-5 mt-2">
          <div class="flex-col flex" v-on:click="routeToWheel">
            <img src="../assets/wheel.svg" alt="wheel" class="ms-2" style="height: 70px; width: 70px;">
            <h1 class="text-white">Play Now!</h1>
          </div>
        </div>
        <div class="relative mt-10 rounded-full overflow-hidden mx-5 h-20 flex items-center bg-opacity-0" :class="canClaim == true ? 'bg-white justify-center' : 'bg-primary-border justify-start'" v-on:click="showModal">
          <div class="bg-primary-border absolute rounded-full h-20 w-full"></div>
          <div class="absolute rounded-l-full bg-progress-bg h-20" :style="getBorderStyle"></div>
          <h1 class="relative text-white text-sm pl-6" v-if="!canClaim && user.lastClaimed != 0">In Storage</h1>
          <h1 class="relative text-black text-base" v-if="canClaim && user.lastClaimed != 0">Claim</h1>

          <h3 class="relative text-white text-xl font-bold text-center" v-if="user.lastClaimed == 0">Start Mining!</h3>

          <h1 class="relative text-white text-3xl font-bold ms-10" v-if="user.lastClaimed != 0">{{ user.storage.toFixed(2) }}</h1>
        </div>
      </div>
      <div class="w-screen flex justify-center">
        <h1 class="text-white mt-5 font-bold text-center">{{ countdown }}</h1>
      </div>
    </div>
  </div>
  </template>

<script>
import userService from '@/service/userService';
import mineService from '@/service/mineService';
import adService from '@/service/adService';
import initFirebase from '../firebase';
import { logEvent } from 'firebase/analytics';

const { analytics } = initFirebase();

export default {
  name: 'HomeView',

  methods: {
    showModal() {
      if(this.$store.state.user.lastClaimed == 0) {
        this.startMining();
      }

      const canClaim = this.$store.state.canClaim;

      if(!canClaim) return;

      document.getElementById('modal-2x').showModal();
    },

    closeModal() {
      document.getElementById('modal-2x').close();
    },

    async claim(isDouble) {
        this.closeModal();

        const canClaim = this.$store.state.canClaim;

        if(!canClaim) return;

        if(isDouble) {
          try {
            await adService.showAd();
          } catch (error) {
              console.log(error);
          }
        }

        await userService.claimReward(this.user.id, isDouble);
        const res = await userService.getUser(this.user.id);

        logEvent(analytics, 'claim_reward', {
          userId: this.user.id,
          balance: this.user.balance + this.user.storage,
        });

        this.$store.commit('setUser', res.user);
        this.$store.commit('setCanClaim', false);

        const nextClaim = res.nextClaim;
        const nextDailyClaim = res.nextDailyClaim;
        const nextWheelClaim = res.nextWheelClaim;
        const nextClaimDate = new Date(nextClaim);
        const nextDailyClaimDate = new Date(nextDailyClaim);
        const nextWheelClaimDate = new Date(nextWheelClaim);

        this.$store.commit('setNextClaim', nextClaimDate);
        this.$store.commit('setNextWheelClaim', nextWheelClaimDate);
        this.$store.commit('setNextDailyClaim', nextDailyClaimDate);

        mineService.stopMining();
        mineService.startMining();

        const countDownInterval = setInterval(() => {
          const currentTime = new Date().getTime();

          const timeLeft = nextClaimDate - currentTime;
          
          if (timeLeft < 0) {
            this.$store.commit('setCountdown', '00:00:00');

            clearInterval(this.$store.state.countDownInterval);

            return;
          }

          const hours = Math.floor(timeLeft / (1000 * 60 * 60)).toString().padStart(2, '0');
          const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
          const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000).toString().padStart(2, '0');


          this.$store.commit('setCountdown', `${hours}:${minutes}:${seconds}`);
        }, 1000);

        this.$store.commit('setCountdownInterval', countDownInterval);

        this.$toast.success('Claimed successfully!');
    },

    startMining() {
      console.log('start mining', this.user.id);
      userService.startMining(this.user.id);

      mineService.startMining();

      this.$store.commit('setLastClaimed', new Date());
    }, 

    routeToWheel() {
      this.$router.push({ path: '/rewards' });
      this.$store.commit('setSelectedTab', 'rewards');
    }
  },  

  mounted() {
    window.Telegram.WebApp.expand();  
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    storage() {
      return this.$store.state.user.storage;
    },
    mineBoxes() {
      return this.$store.state.mineBoxes;
    },
    nextClaimDate() {
      return this.$store.state.nextClaim;
    },
    
    getBorderStyle() {
      const currentTime = new Date().getTime();
      const nextClaimTime = this.nextClaimDate;
      const lastClaimedTime = this.$store.state.user.lastClaimed._seconds;

      const timeLeft = nextClaimTime - currentTime;
      const totalTime = nextClaimTime - lastClaimedTime;

      const progress = ((totalTime - timeLeft) / totalTime) * 100;

      console.log(`Time Left: ${timeLeft / 1000} seconds`);
      console.log(`Total Time: ${totalTime / 1000} seconds`);
      console.log(`Progress: ${progress}%`);

      return {
        width: `${progress}%`
      };
    },

    countdown() {
      return this.$store.state.countdown;
    }, 

    canClaim() {
      return this.$store.state.canClaim;
    }
  }
}
</script>


<style>
  .home {
    overflow: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .home::-webkit-scrollbar {
    display: none;
  }

  .content {
    position: relative;
  }

  .claim-bg {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
  }
</style>s