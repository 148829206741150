const axios = require('axios');
const config = require('../config');

const createUser = async (user) => {
    const url = `${config.default.baseUrl}/user`;

    try {
        const response = await axios.post(url, user);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

const getUser = async (userId) => {
    const url = `${config.default.baseUrl}/user`;

    try {
        const response = await axios.get(url, {
            params: {
                id: userId,
            }
        });

        if (response.data) {
            return response.data;
        } 

        return undefined;
    } catch (error) {
        return undefined;
    }
}

const upgradeMining = async (userId, boxLevel) => {
    const url = `${config.default.baseUrl}/user/${userId}/upgrade`;

    try {
        const response = await axios.post(url, { boxLevel });
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

const claimReward = async (userId, isDouble) => {
    const url = `${config.default.baseUrl}/user/${userId}/claim`;

    try {
        const response = await axios.post(url, { isDouble });
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

const upgradeStorage = async (userId, claimLevel) => {
    const url = `${config.default.baseUrl}/user/${userId}/upgrade-claim`;

    try {
        const response = await axios.post(url, { claimLevel });
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

const startMining = async (userId) => {
    const url = `${config.default.baseUrl}/user/start`;

    try {
        const response = await axios.post(url, { userId });
        return response.data;
    } catch (error) {
        console.error(error);
    }
} 

const updateBalance = async (userId, amount) => {
    const url = `${config.default.baseUrl}/user/${userId}/balance`;

    try {
        const response = await axios.post(url, { amount });
        return response.data.user;
    } catch (error) {
        console.error(error);
    }
}

const claimDaily = async (userId) => {
    const url = `${config.default.baseUrl}/user/${userId}/claim-daily`;

    try {
        const response = await axios.post(url);
        
        return response.data;
    } catch (error) {
        return { error: error.response.data.error };
    }
};

const claimRef = async (userId, level) => {
    const url = `${config.default.baseUrl}/user/${userId}/claim-ref`;

    try {
        const response = await axios.post(url, { level });
        
        return response.data.user;
    } catch (error) {
        console.error(error);
    }
};

const claimWheelReward = async (userId, amount) => {
    const url = `${config.default.baseUrl}/user/${userId}/claim-wheel`;

    try {
        const response = await axios.post(url, { amount });
        return response.data.user;
    } catch (error) {
        console.error(error);
    }
}

const claimCombo = async (userId, comboCode) => {
    try {
        const response = await axios.post(`${config.default.baseUrl}/user/${userId}/claim-combo`, { comboCode });

        return response.data;
    } catch(e) {
        return e;
    }
}

export default {
    createUser,
    getUser,
    upgradeStorage,
    claimReward,
    upgradeMining,
    startMining,
    updateBalance,
    claimDaily,
    claimRef,
    claimWheelReward,
    claimCombo,
};